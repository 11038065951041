<template>
  <v-container fluid>
    <!--BOC:[breadcrumb]-->
    <!-- <ABreadcrumb :items="breadcrumb.items" /> -->
    <!--EOC-->
    <!--BOC:[dashboard]-->
    <div v-if="currentService">
      <div class="mb-3">
        <div class="text-h5">{{ currentService.name }}</div>
      </div>
      <ADashboard :modules="menu" />
    </div>
    <div v-else>
      <ALoader/>
    </div>
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[menu]
import configMenu from "@/config/menu";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
    currentService: (state) => state.service.data.currentService,
    auth: (state) => state.auth.data,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    //BOC:[service]
    service: null,
    //EOC
    //BOC:[menu]
    menu: [],
    //EOC
  }),
  created() {
    //BOC:[service]
    var serviceKey = this.$route.params.serviceKey;
    if (serviceKey) this.service = this.$_.find(this.services,{key:serviceKey});
    //EOC
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: this.service.name,
      to: {
        name: "PageServiceDashboard",
        params: { serviceKey: this.service.key },
      },
      exact: true,
    });
    //EOC
    //BOC:[menu]
    var targetMenu = configMenu[this.service.key];
    if(targetMenu) this.menu = this.$_.clone(targetMenu);
    //EOC

    // Process menu data based on services.
    let rolesArray;
    const disabledRolesForHrdc = "taxpod-salesperson";
    switch (this.service.key) {
      case "hrdc":
        // Retrieve auth data roles & store into array.
        rolesArray = this.auth.roles.map(item => item.slug);
        if (rolesArray.length == 1 && rolesArray[0] == disabledRolesForHrdc) {
          for (const menu of this.menu) {
            menu.isAllowed = true;
            if (menu.disabledForRoles && menu.disabledForRoles.includes(disabledRolesForHrdc)) {
              menu.isAllowed = false;
            }
          }
        }
        break;
    }
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>